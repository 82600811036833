import React from 'react';
import { bool, func } from 'prop-types';
import loadable from '@loadable/component';

import {
  Box,
  Button,
  Center,
  Text,
  Image,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Modal,
  Heading,
  AspectRatio,
} from '@chakra-ui/react';

import { BUTTON_PADDING_PROPS, BUTTON_PROPS } from '@/constants/colors';
import { ENABLE_VIDEO_PREWEDDING } from '@/constants/feature-flags';
import { BRIDE_HASHTAG, PREWEDDING_EMBED, THE_BRIDE } from '@/constants';
import { ASSETS_BG_PATTERN, IMG_GALLERY_COVER, IMG_LOGO_INVERT } from '@/constants/assets';

import useLang from '@hooks/useLang';

import { photos as photosLive } from './api/photos-2';

import txt from './locales';

const LoadableGallerry = loadable(() =>
  import(/* webpackChunkName: "invitato-gallery-photos" */ './Gallery'),
);

function PhotoContent({ isOpen, onClose }) {
  const lang = useLang();

  return (
    <Modal size="full" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        maxW="500px"
        bgColor="bgPrimary"
        bgSize="contain"
        bgImage={`url('${ASSETS_BG_PATTERN}')`}
      >
        <ModalCloseButton color="transparent" {...BUTTON_PROPS} />
        <ModalBody width="100%" padding="0">
          <Box
            bgImage={`url(${IMG_GALLERY_COVER})`}
            height="80vh"
            bgSize="cover"
            bgPosition="bottom center"
          />
          <Text fontSize="3xl" textAlign="center" color="bgPrimary" transform="translateY(-80px)">
            {BRIDE_HASHTAG}
          </Text>
          {/* Images Cover */}
          <Box
            padding="32px"
            paddingTop="20px"
            textAlign="center"
            color="mainColorText"
            fontFamily="body"
          >
            <Heading size="xl" fontWeight="normal" fontFamily="LScript" color="secondaryColorText">
              About Us
            </Heading>
            <Text marginTop="24px" fontSize="md">
              “Loved you yesterday, love you still, always have, always will.” <br />– Elaine Davis
            </Text>
            <Box paddingTop="24px" marginTop="-42px">
              <Box marginTop="70px" position="relative" zIndex={2}>
                {isOpen && <LoadableGallerry data={photosLive} />}
              </Box>
              {false && ENABLE_VIDEO_PREWEDDING && (
                <Box marginTop="120px">
                  <AspectRatio marginTop="-100px" maxW="560px" ratio={16 / 9} boxShadow="xl">
                    <iframe
                      title={`Prewedding of ${THE_BRIDE}`}
                      src={PREWEDDING_EMBED}
                      allowFullScreen
                      loading="lazy"
                      style={{ borderRadius: '16px' }}
                    />
                  </AspectRatio>
                </Box>
              )}
            </Box>
          </Box>
          <Center>{false && <Image src={IMG_LOGO_INVERT} maxW="100px" marginTop="16px" />}</Center>
          <Center padding="16px 0 82px 0">
            <Button
              size="md"
              color="white"
              bgColor="bgAlternative"
              boxShadow="md"
              fontWeight="normal"
              onClick={() => onClose()}
              fontFamily="body"
              {...BUTTON_PADDING_PROPS}
            >
              {txt.back[lang]}
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

PhotoContent.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default PhotoContent;
