import React, { useEffect } from 'react';
import { string } from 'prop-types';

import SinglePhoto from './SinglePhoto';
import useRsvp from '@/usecase/use-rsvp';

/**
 * Render couple info
 * @param {*} props
 * @returns
 */
function CoupleInfo({ ...rest }) {
  const { onOpenInvitation } = useRsvp();

  useEffect(() => {
    onOpenInvitation();
  }, []);

  return <SinglePhoto {...rest} />;
}

CoupleInfo.propTypes = {
  lang: string,
};

export default CoupleInfo;
