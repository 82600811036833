import React from 'react';
import { Box, Center, Text, Link, Image, Divider } from '@chakra-ui/react';

import useLang from '@hooks/useLang';
import useInvitation from '@hooks/useInvitation';

import LazyImage from '@components/Common/LazyImage';
import WithAnimation from '@/components/Common/WithAnimation';
import DetailCouple from '@/components/Section/CoupleInfo/DetailCard';

import { ENABLE_COLLABORATION, ENABLE_MUSLIM_LANGUAGE, SELECTED_VENDOR_COLLABORATION } from '@/constants/feature-flags';
import { IMG_FOOTER, LOGO_INVITATO } from '@/constants/assets';
import { INVITATO_URL, SOUND_BY, SOUND_URL, THE_BRIDE } from '@/constants';

import { FOOTER_LINK_PROPS } from './types';
import txt from './locales';

function FooterSection() {
  const isInvitation = useInvitation();
  const lang = useLang();

  return (
    <Box bgSize="cover" width="100%" pos="relative">
      <Box textAlign="center" color="mainColorText" width="100%" position="relative">
        <Box padding="42px 24px">
          <WithAnimation>
            <Text margin="24px 0 24px 0" fontSize="md" padding="0 12px 0 12px">
              {isInvitation ? txt.closingInvitation[lang] : txt.closingAnnouncement[lang]}
              {ENABLE_MUSLIM_LANGUAGE && (
                <>
                  <br /> <br />
                  Wassalamualaikum Warahmatullahi Wabarakatuh.
                </>
              )}
            </Text>
          </WithAnimation>
          <WithAnimation>
            <Text
              fontFamily="CustomFont"
              margin="0 0 24px 0"
              color="secondaryColorText"
              textTranform="lowercase"
              fontSize="xl"
            >
              our sincere,
            </Text>
          </WithAnimation>
          <WithAnimation>
            <DetailCouple
              shortName={THE_BRIDE.replace('&', '+ <br />')}
              flowerProps={{ display: 'none' }}
              imgUrl={IMG_FOOTER}
              customProps={{
                height: '300px',
                widthRatio: '220px',
                profileWidth: `300px`,
                profileBottomProps: { marginTop: '-32px' },
              }}
            />
          </WithAnimation>
        </Box>
      </Box>
      {/* INVITATO SECTION */}
      <Box bgColor="#EFE8D9" padding="24px" textAlign="center">
        {/* INVITATO SECTION */}
        <Box>
          <WithAnimation>
            <Center>
              <Link href={INVITATO_URL} target="_blank">
                <LazyImage src={LOGO_INVITATO} filter="invert(1)" maxWidth="110px" margin="0" />
              </Link>
            </Center>
          </WithAnimation>
          <WithAnimation>
            <Text textAlign="center" color="mainColorText" fontSize="sm" margin="0 0 12px 0">
              Created with love by Invitato
            </Text>
          </WithAnimation>
          {ENABLE_COLLABORATION && (
            <Box margin="-5px 0 16px">
              <Center>
                <Divider
                  margin="5px 0 12px"
                  maxWidth="60px"
                  opacity="1"
                  height="1px"
                  bgColor="mainColorText"
                  border="none"
                  borderBottom="none"
                />
              </Center>
              <Center>
                <Link href={`https://www.instagram.com/${SELECTED_VENDOR_COLLABORATION.instagram}/`} target="_blank">
                <Image
                      src={SELECTED_VENDOR_COLLABORATION.imgUrl}
                      {...SELECTED_VENDOR_COLLABORATION.imgProps}
                    />
                </Link>
              </Center>
              <Center>
                <Text color="mainColorText" align="center" fontSize="sm">
                  In Collaboration with {SELECTED_VENDOR_COLLABORATION.vendorName}
                </Text>
              </Center>
            </Box>
          )}
          <Text {...FOOTER_LINK_PROPS}>
            {`© ${new Date().getFullYear()} ${THE_BRIDE}. All Right Reserved`}
          </Text>
          <Link {...FOOTER_LINK_PROPS} href={SOUND_URL}>
            <Text
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >{`Song by ${SOUND_BY}`}</Text>
          </Link>
          <Link
            {...FOOTER_LINK_PROPS}
            href="https://pngtree.com/freepng/wedding-european-arch-roman-arch-material_7098799.html"
          >
            <Text
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >{`Arch by PNG Tree`}</Text>
          </Link>
          <Link
            {...FOOTER_LINK_PROPS}
            href="https://collection.cooperhewitt.org/objects/18558563/#image-rights"
          >
            <Text
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >{`Wallpaper Design by Cooper Hewitt`}</Text>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(FooterSection);
