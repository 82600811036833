import React, { useState, useCallback } from 'react';
import { Box, Image } from '@chakra-ui/react';

import { bool, func, string } from 'prop-types';

import CoverSection from '@components/Section/Cover';
import CoupleInfoSection from '@components/Section/CoupleInfo';
import WelcomeSection from '@/components/Section/Welcome';

import CountingDown from '@components/Section/CountingDown';
import WeddingSection from '@components/Section/Wedding';
import RSVPSection from '@components/Section/RSVP/Lazy';
import YoutubeLive from '@components/Section/YoutubeLive/Lazy';
import GiftCard from '@components/Section/GiftCard';
import WishesSection from '@components/Section/Wishes';
import PhotoGallery from '@components/Section/PhotoGallery';
import Footer from '@components/Section/Footer';
import InstagramFilter from '@components/Section/InstagramFilter/WithoutFlower';

import Layout from '@components/Layout';
import FloatingMusic from '@components/Common/FloatingMusic/Lazy';
import Navigation from '@components/Common/Navigation';
import CovidModal from '@components/Common/CovidModal/Lazy';
import DarkmodeNotification from '@components/Common/DarkmodeNotification/Lazy';
import WithAnimation from '@components/Common/WithAnimation';

import { GuestProvider } from '@/context/guest';
import useInvitation from '@/hooks/useInvitation';
import useLang from '@/hooks/useLang';
import * as ID from '../constants/identifier';
import * as F from '../constants/feature-flags';
import { ASSETS_BG_PATTERN, ASSETS_PATTERN, ASSETS_PATTERN_SHORT } from '@/constants/assets';
import { Fragment } from 'react/cjs/react.production.min';

const ELEMENT_CONTENT_ID = 'invitato-detail-content';

export default function Home() {
  const [showDetailContent, setShowDetailContent] = useState(false);
  const lang = useLang();

  /**
   * function to show detail invitation
   * @returns {void}
   */
  const handleClickDetail = useCallback(() => {
    setShowDetailContent(true);
  }, []);

  /**
   * function to render invitation content
   * @returns {JSX.Element}
   */

  return (
    <GuestProvider>
      <Layout>
        <HomePageContent
          handleClickDetail={handleClickDetail}
          showDetailContent={showDetailContent}
          lang={lang}
        />
      </Layout>
    </GuestProvider>
  );
}

function HomePageContent({ handleClickDetail, showDetailContent, lang }) {
  const invitation = useInvitation();

  return (
    <>
      {F.ENABLE_MUSIC_BACKSOUND && <FloatingMusic shouldShowMusicIcon={showDetailContent} />}
      {F.ENABLE_DARKMODE_NOTIFICATION && <DarkmodeNotification lang={lang} />}
      {showDetailContent && <Navigation lang={lang} />}
      {F.ENABLE_COVID_POPUP && showDetailContent && invitation && (
        <CovidModal visible={showDetailContent} lang={lang} />
      )}
      <WithAnimation top collapse when={!showDetailContent}>
        <CoverSection loaded={showDetailContent} onSeeDetail={handleClickDetail} />
      </WithAnimation>
      {showDetailContent ? (
        <Box id={ELEMENT_CONTENT_ID}>
          <CountingDown lang={lang} />
          <Box bgImage={`url(${ASSETS_BG_PATTERN})`} bgSize="contain" position="relative">
            <Box
              bgImage={`url('${ASSETS_PATTERN}')`}
              bgSize="contain"
              height="70px"
              backgroundRepeat="repeat-x"
              bgPos="bottom"
            />
            <Box padding="32px">
              <WelcomeSection />
              <CoupleInfoSection name={ID.ID_COUPLE_SECTION} lang={lang} />
            </Box>
            <WeddingSection name={ID.ID_DETAIL_SECTION} lang={lang} />
            {invitation && (
              <Box height="60px">
                <Image src={ASSETS_PATTERN_SHORT} alt="" pos="absolute" left="0" width="100px" />
                <Image src={ASSETS_PATTERN_SHORT} alt="" pos="absolute" right="0" width="100px" />
              </Box>
            )}
            {F.ENABLE_RSVP && invitation && (
              <Fragment>
                <RSVPSection lang={lang} name={ID.ID_RSVP_SECTION} />
                <WithAnimation right>
                  <Box
                    bgImage={`url('${ASSETS_PATTERN}')`}
                    bgSize="contain"
                    height="70px"
                    backgroundRepeat="repeat-x"
                    transform="rotate(180deg)"
                  />
                </WithAnimation>
              </Fragment>
            )}
          </Box>
          <Box
            bgImage={`url(${ASSETS_BG_PATTERN})`}
            bgSize="contain"
            position="relative"
            padding="32px"
          >
            {(F.ENABLE_LIVE_STREAMING || F.ENABLE_VIDEO_PREWEDDING) && (
              <YoutubeLive lang={lang} name={ID.ID_YOUTUBE_LIVE} />
            )}
            <Image src={ASSETS_PATTERN_SHORT} alt="" pos="absolute" left="0" width="100px" />
            <Image src={ASSETS_PATTERN_SHORT} alt="" pos="absolute" right="0" width="100px" />
            <Box height="48px" />
            {F.ENABLE_GIFT_CARD && <GiftCard lang={lang} name={ID.ID_GIFT_SECTION} />}
            <PhotoGallery lang={lang} />
            <Box height="48px" />
            {invitation && (
              <Box>
                <Image src={ASSETS_PATTERN_SHORT} alt="" pos="absolute" left="0" width="100px" />
                <Image src={ASSETS_PATTERN_SHORT} alt="" pos="absolute" right="0" width="100px" />
              </Box>
            )}
            <Box height="48px" />
            {F.ENABLE_IG_FILTER && invitation && <InstagramFilter />}
          </Box>
          <Box bgImage={`url(${ASSETS_BG_PATTERN})`} bgSize="contain" position="relative">
            <WithAnimation left>
              <Box
                bgImage={`url('${ASSETS_PATTERN}')`}
                bgSize="contain"
                height="70px"
                backgroundRepeat="repeat-x"
                marginTop="-32px"
              />
            </WithAnimation>
            <Box padding="32px">
              <WishesSection lang={lang} />
            </Box>
            <Footer lang={lang} />
          </Box>
        </Box>
      ) : null}
    </>
  );
}

HomePageContent.propTypes = {
  handleClickDetail: func,
  showDetailContent: bool,
  lang: string,
  getCode: string,
};
